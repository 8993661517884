let map: google.maps.Map
export async function initMap(mapEl: HTMLElement) {
    if (!mapEl) return

    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    const mapCenter = { lat: 50.064909716840454, lng: 14.503235953371524 },
        themeRoot = mapEl.dataset.themeUri

    map = new Map(mapEl, {
        center: mapCenter,
        zoom: 14,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: mapStyle
    });

    new google.maps.Marker({
        position: mapCenter,
        clickable: false,
        icon: `${themeRoot}/resources/images/map-logo.svg`,
        map: map
    });

    map.addListener("tilesloaded", () => mapEl.classList.remove('is-loading'))

    const insertBefore = document.head.insertBefore
    document.head.insertBefore = function(newElement: Node, referenceElement: Node) {
        const newLinkElement = newElement as HTMLLinkElement;
        if (newLinkElement.href && newLinkElement.href.indexOf("//fonts.googleapis.com/") > -1) return null;

        insertBefore.call(document.head, newElement, referenceElement);
    }
}

const mapStyle = [{
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [{ "visibility": "simplified" }]
}, {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [{ "visibility": "simplified" }, { "color": "#fcfcfc" }]
}, {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [{ "visibility": "off" }]
}, {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{"visibility": "simplified"}, {"color": "#fcfcfc"}]
}, {
    "featureType": "road",
    "elementType": "all",
    "stylers": [{"saturation": "-100"}]
}, {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [{"saturation": "-100"}, {"lightness": "25"}]
}, {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{"visibility": "simplified"}, {"color": "#dddddd"}]
}, {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [{"visibility": "simplified"}, {"color": "#dddddd"}]
}, {
    "featureType": "road.arterial",
    "elementType": "labels.text",
    "stylers": [{"visibility": "on"}, {"weight": "1.03"}]
}, {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [{"visibility": "simplified"}, {"color": "#eeeeee"}]
}, {
    "featureType": "road.local",
    "elementType": "labels.text",
    "stylers": [{"visibility": "off"}, {"gamma": "1.08"}]
}, {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [{"visibility": "simplified"}]
}, {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [{"lightness": "64"}]
}, {
    "featureType": "transit",
    "elementType": "labels.icon",
    "stylers": [{"visibility": "off"}]
}, {
    "featureType": "water",
    "elementType": "all",
    "stylers": [{"saturation": "-100"}]
}, {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{"visibility": "simplified"}, {"color": "#c9dbe4"}]
}, {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [{"visibility": "off"}]
}];