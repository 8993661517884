import {SwiperOptions} from "swiper/types";

const initSwiper = async () => {
    const sliders = document.querySelectorAll<HTMLElement>('[data-swiper]');
    if (!sliders.length) return;

    const { default: Swiper } = await import("swiper");
    const { Navigation } = await import("swiper/modules");

    const swiperDefinitions: {[key: string]: SwiperOptions} = {
        'ModelSelection': {
            slidesPerView: 2,
            spaceBetween: 4,
            modules: [ Navigation ],
            navigation: {
                nextEl: "[data-model-selection-next]",
                prevEl: "[data-model-selection-prev]"
            },
            breakpoints: {
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 12
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 24
                }
            }
        }
    }

    sliders.forEach(slider => { new Swiper(slider, swiperDefinitions[slider.dataset.swiper] ?? {}) })
}

export default initSwiper