const initLightbox = async () => {
    const galleries = document.querySelectorAll<HTMLElement>('[data-offer-gallery], .wp-block-gallery:has(a[href])')
    if (!galleries.length) return;

    const { default: SimpleLightbox } = await import("simplelightbox/dist/simple-lightbox.esm");

    galleries.forEach(gallery => {
        const galleryLightbox = new SimpleLightbox(gallery.querySelectorAll('figure > a'), {
            captionSelector: '+ figcaption',
            captionType: 'text',
            disableRightClick: true,
            overlayOpacity: 0.96
        })

        const opener = gallery.querySelector('[data-open-offer-gallery]') as HTMLElement
        opener?.addEventListener("click", () => {
            galleryLightbox.open(undefined, gallery.childElementCount - parseInt(opener.dataset.openOfferGallery) - 1)
        })
    })
}

export default initLightbox