import Lenis from "lenis";
import {animate, frame, inView, scroll, stagger} from "motion";

const initMotion = () => {
    const smoothScroll = new Lenis(),
        raf = time => smoothScroll.raf(time.timestamp)

    frame.update(raf, true);

    document.querySelectorAll<HTMLLinkElement>('[data-swiper="ModelSelection"] a[href]')
        .forEach(link => link.addEventListener('click', () => {
            const href = link.getAttribute('href')
            href.startsWith('#') && smoothScroll.scrollTo(link.getAttribute('href'))
        }));

    (() => {
        const homeIntro = document.querySelector<HTMLVideoElement>('[data-home-intro]'),
            homeLoop = document.querySelector<HTMLVideoElement>('[data-home-loop]'),
            header = document.querySelector<HTMLElement>('[data-header]')

        if (!homeLoop) return
        if (!homeIntro || window.scrollY > 40) header.style.opacity = '1'

        animate([
            [ header, { opacity: 1 }, { duration: homeIntro ? 1.6 : 0 } ],
            [ ".HomeHeroCover", { y: [40, 0] }, { duration: .8, at: '-.4', } ],
            [ ".HomeHeroIntro small", { filter: "opacity(1)" }, { at: '-.4', duration: .4 } ],
        ], { delay: homeIntro ? 2.4 : 0 })

        homeIntro?.addEventListener("ended", () => {
            homeLoop.play().then(() => homeIntro.classList.add('u-hidden'))
        })

        scroll(animate(".HomeHeroCover", { clipPath: "inset(0)" }), {
            target: homeLoop,
            offset: ["end end", "end start"]
        })

        const fade = animate(".HomeHeroIntroduction p", {
            opacity: [0, 1],
            y: [0, '-40vh']
        }, {
            delay: stagger(.12),
            duration: .12,
            y: { duration: 1 }
        })

        scroll(fade, {
            target: document.querySelector(".HomeHeroIntroduction"),
            offset: ["start 64vh", "end start"]
        })
    })();

    (async () => {
        const showroomWalkthrough = document.querySelector("[data-showroom-walkthrough]") as HTMLElement,
            video = showroomWalkthrough?.querySelector("video");

        if (!video) return;

        const uncover = animate([
            [ ".ShowroomWalkthroughCover", { clipPath: "inset(0 0 100vh)" } ]
        ])

        scroll(uncover, {
            target: showroomWalkthrough,
            offset: ["20vh end", "120vh end"]
        });

        const setUpWalkthrough = () => {
            scroll((progress: number) => {
                if (video.readyState < 1) return
                video.currentTime = video.duration * progress
            }, {
                target: showroomWalkthrough
            });
        }

        fetch(video.dataset.src, {
            cache: "force-cache"
        })
            .then((response) => response.blob())
            .then((response) => {
                const objectURL = URL.createObjectURL(response);
                video.setAttribute("src", objectURL);
                video.load();
            })
            .finally(() => setUpWalkthrough());
    })();

    (() => {
        const modelsPresentation = document.querySelector<HTMLElement>('[data-models-presentation]'),
            slides = Array.from(modelsPresentation?.querySelectorAll<HTMLElement>('[data-model-slide]') ?? [])

        if (!modelsPresentation) return

        const fadeIn = animate([
            [ modelsPresentation, { opacity: 1 } ],
            [ slides[0].querySelector('img'), { scale: [.88, 1] }, { at: '<' } ]
        ])

        scroll(fadeIn, {
            target: modelsPresentation,
            offset: ["100vh end", "120vh end"]
        })

        const virtualHeight = parseInt(getComputedStyle(modelsPresentation).getPropertyValue('--ModelSlideVirtualHeight').replace('vh', '')),
            totalHeight = 100 + 4 * virtualHeight

        scroll((progress:number) => {
            const active = Math.max(1, Math.min(Math.round(progress * 4), slides.length))
            slides.forEach(slide => slide.classList.toggle('is-visible', slides.indexOf(slide) + 1 === active))
        }, {
            target: modelsPresentation,
            offset: ["100vh end", `${totalHeight}vh end`]
        })

        scroll(animate(modelsPresentation, { clipPath: "inset(0 0 100vh)" }), {
            target: modelsPresentation,
            offset: [`${totalHeight}vh end`, `${totalHeight + 50}vh end`]
        })
    })();

    (() => {
        const bespokeIntroduction = document.querySelector('[data-bespoke-introduction]'),
            universeHolder = bespokeIntroduction?.querySelector('[data-universe]')

        if (!bespokeIntroduction) return

        inView(bespokeIntroduction, () => {
            for (let i = 0; i < 240; i++) {
                const star = document.createElement('star')
                star.style.top = `${100 * Math.random()}%`
                star.style.left = `${100 * Math.random()}%`

                universeHolder.appendChild(star)
            }

            const shineStar = (star: HTMLElement) => {
                return animate(star, { opacity: Math.random() }, { duration: Math.random() * .5 + .5 })
                    .then(() => shineStar(star))
            }

            universeHolder.querySelectorAll<HTMLElement>('star').forEach(star => shineStar(star))
        })

        const fadeIn = animate([
            [ universeHolder, { opacity: 1 }, { duration: .2 } ],
            [ ".BespokeIntroductionInner :is(h2, p, a[href])", { opacity: [0, 1], y: ['2rem', '0'] }, { delay: stagger(.08) }],
            [ ".BespokeIntroductionInner", { y: [0, '-50%'] }, { at: '+.4', duration: .8 } ],
            [ ".BespokeIntroductionAtelier", { clipPath: 'inset(0)' }, { at: '<', duration: .8 } ],
            [ ".BespokeIntroductionAtelier > img", { y: ['-16vh', '0'] }, { at: '<', duration: 1.32 } ]
        ])

        scroll(fadeIn, {
            target: bespokeIntroduction,
            offset: ["100vh end", "end start"]
        })
    })();
}

export default initMotion