import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import {isInViewport} from "./utils/is-in-viewport";
import initLightbox from "./components/lightbox";
import {initMap} from "./components/map";
import initMotion from "./components/motion";
import initSwiper from "./components/swiper";
import initModal from "./components/modal";

import.meta.glob([ '@images/**' ])
import.meta.env.DEV && import("@styles/main.sass");

declare global {
    interface Window {
        mapLoaded: boolean;
    }
}

(() => {
    // TODO: doplnit aria attributy
    const header = document.querySelector<HTMLElement>('[data-header]'),
        menu = header?.querySelector('dialog'),
        models = header?.querySelector('ul[popover]')

    header.querySelector('[data-menu-opener]')?.addEventListener("click", () => menu.showModal())
    menu.querySelector('[data-menu-opener]')?.addEventListener("click", () => menu.close())

    models?.addEventListener("beforetoggle", (event: ToggleEvent) => {
        if (event.oldState === "closed" && !menu.open) {
            menu.setAttribute('data-model-view', 'true');
            menu.showModal()
        }

        if (event.oldState === "open" && menu.dataset.modelView) {
            menu.close();
            menu.removeAttribute('data-model-view');
        }
    })
})();

(async () => {
    window.mapLoaded = false;

    initModal();
    initMotion();
    await initLightbox();
    await initSwiper();

    const loadMap = async () => {
        const mapEl = document.querySelector("[data-showroom-map]") as HTMLElement

        if (window.mapLoaded || !isInViewport(mapEl, 4))
            return;

        window.mapLoaded = true;
        await initMap(mapEl);
    };

    document.addEventListener("DOMContentLoaded", loadMap);
    document.addEventListener("scroll", loadMap);
})();

// TODO: optimize loading only when needed
(() => {
    if (document.body.classList.contains('blog')) {
        const postArchive = document.querySelector('[data-post-archive]')

        Alpine.store('postArchiveStack', [])

        Livewire.on('load-more-posts', () => postArchive.querySelectorAll('article:not([data-key])')
            .forEach(el => Alpine.store('postArchiveStack').push(el.outerHTML.replace('wire:', 'data-'))))

        Livewire.on('change-archive-page', () => {
            Alpine.store('postArchiveStack', [])
            postArchive?.scrollIntoView()
        })

        Livewire.on('change-archive-url', (details: { url: string }) => history.replaceState({}, '', details.url))
    }

    Livewire.start();
})();
